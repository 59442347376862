import { connect } from 'react-redux';
import * as actionsAlerts from '../../actions/AlertsActions';
import { copyComment, editComment, getAddressByLatLong } from '../../actions/GlobalsActions';
import { DRAW_TYPES } from '../../constants/Misc';

import { getPcaForAlert } from "../../actions/actions";
import { setMapDrawMode } from "../../actions/setters";
import DetailsTab from '../../components/AlertsTabs/DetailsTab/DetailsTab';

const diff = require('deep-diff').diff;
const _ = require('lodash');

const mapStateToProps = (state, ownProps) => {
  var workAreaOptions = state.leaksList.optionList.options.WorkAreas || [];
  var serviceProviderOptions = state.leaksList.optionList.options.ContractorID || [];
  var repairOptions = state.leaksList.optionList.options.PCARepairCodesEnum || [];
  const { selectedProject, user } = state.leaksList;
  const distributionListsCustomers = process.env.REACT_APP_DISTRIBUTION_LISTS_CUSTOMERS === 'true';
  const distributionLists = distributionListsCustomers ? state.distributionLists.lists : state.distributionLists.projectsDLists || [];
  const distributionListsOptions = distributionLists.map((listItem) => ({
    label: distributionListsCustomers ? listItem.Name : listItem.name,
    value: distributionListsCustomers ? listItem.ID : listItem.id,
  }));


  if (selectedProject) {
    const leaks = state.leaksList.leaksByProject[selectedProject];
    const indexMap = leaks.indexMap;
    const leaksItems = leaks.items;
    const selectedFeature = state.leaksList.leaksByProject.selectedFeature;
    const selectedLeakObj = leaksItems[indexMap[selectedFeature]];
    const leakDetails = leaks.details[selectedFeature];
    const images = leaks.images;
    const isFixedAlert = (selectedLeakObj != null && selectedLeakObj.AlertState == 6);

    if (leakDetails != null && leakDetails.data != null && !_.isEmpty(leakDetails.data)) {
      const initialValues = Object.assign({}, leakDetails.data, selectedLeakObj);
      const units = state.local.units;
      const distanceFactor = state.local.factor[units];
      if (initialValues.Latitude != null) {
        initialValues.Latitude = initialValues.Latitude.toFixed(6);
      }
      if (initialValues.Longitude != null) {
        initialValues.Longitude = initialValues.Longitude.toFixed(6);
      }

      return Object.assign({}, {
        distanceFactor,
        timeZone: state.local.timeZone,
        user,
        serviceProviderOptions,
        workAreaOptions,
        repairOptions,
        selectedFeature,
        selectedProject,
        initialValues,
        isFixedAlert,
        images,
        distributionListsOptions
      }, ownProps);
    }
  }

  return {
    timeZone: state.local.timeZone,
    workAreaOptions,
    repairOptions,
    serviceProviderOptions,
    user,
    initialValues: null,
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmit: (values, dispatch, props) => {
      console.log('values mapDispatchToProps cdetailstab', values);

      const differences = diff(values, props.initialValues);
      if (differences != null) {
        let res = [];
        differences.forEach((diff) => {
          res.push({ field: diff.path[0], value: diff.lhs });
        });
        dispatch(actionsAlerts.updateLeak(res));
      }
    },

    openPCA: (alertId) => {
      dispatch(getPcaForAlert(alertId));
      dispatch(actionsAlerts.setOpenPCA(true, false));
    },

    openActualLeakLocation: () => {
      dispatch(actionsAlerts.openActualLeakLocation(true));
      dispatch(setMapDrawMode(true, DRAW_TYPES.POINT));
    },

    onEdit: (proxi, object, event) => {
      dispatch(editComment('details', 'Comment'));
      return true;
    },

    onCopy: (proxi, object, event) => {
      dispatch(copyComment('details', 'Comment'));
      return true;
    },

    sendNotification: (distributionListId, image) => {
      dispatch(actionsAlerts.notify(distributionListId, image, (err) => {
        if (err) {
          // console.log(err);
        } else {
          const level = 'success';
          const subject = (distributionListId == 'sms' ? 'SMS' : 'EMail');
          const message = `${subject} sent seccessfully`;
          ownProps.pageNotification(message, level);
        }
      }));
    },

    // notifyByEmail: (addressee, mapImageBase64) => {
    //   dispatch(actionsAlerts.notify('email', addressee, mapImageBase64, (err) => {
    //     if (err) {
    //       console.log(err);
    //     } else {
    //       const level = 'success';
    //       const message = 'Mail sent seccessfully'
    //       ownProps.pageNotification(message, level);
    //     }
    //   }));
    // },

    // notifyBySms: (phoneNumber) => {
    //   dispatch(actionsAlerts.notify('sms', phoneNumber, null, (err) => {
    //     if (err) {
    //       console.log(err);
    //     } else {
    //       const level = 'success';
    //       const message = 'SMS sent seccessfully'
    //       ownProps.pageNotification(message, level);
    //     }
    //   }));
    // },

    getAddressByLatLong: (proxi, object, event) => {
      dispatch(getAddressByLatLong('details', 'StreetAddress'));
      return true;
    },

    getMapCapture: () => ownProps.getMapCapture()
  };
}

const CDetailsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsTab);

export default CDetailsTab;
