// import {version} from '../../../../../package.json';
export const versionNumber = '5.1.37' +
  (JSON.parse(process.env.REACT_APP_BETA) ? ' β 5' : '');


export const routes = {
  TERMS: 'terms',
  LOGIN: 'login',
  ALERTS: 'alerts',
  SENSORS: 'sensors',
  COUPLES: 'couples',
  MANAGE: 'manage',
  INSTALL: 'install',
  INPECT: 'inspect',
  REPORTS: 'reports',
  MOBILE: 'mobile',
  G5DevicesManagement: 'g5Devices'
};

export const dateFields = ['ClosureDate', 'CommentDate', 'DetectedAt', 'detected', 'fixDate'];
export const REPORTS_TYPE = {
  ALERTS: 'alerts',
  SENSORS: 'sensorsByProjects',
  SENSORS_STATUS: 'sensorsStatus',
};
export const REPORTS_TYPE_OPTIONS = [
  { value: REPORTS_TYPE.ALERTS, label: 'Alerts' },
  { value: REPORTS_TYPE.SENSORS, label: 'Sensors "By Projects"' },
  { value: REPORTS_TYPE.SENSORS_STATUS, label: 'Sensors Status' },
];

export const SOPS_TYPES = [
  'undefined',
  'hydrant',
  'hydrant_underground',
  'valve',
  'gate_valve',
  'control_valve',
  'service_valve',
  'manhole',
  'fitting'
];

export const InstallOperationMode = {
  SOP_ONLY: 'sop only',
  SOP_AND_SENSOR: 'sop ans sensor position',
  SOP_AND_SENSOR_AND_DEVICE: 'sop with sensor and device',
};

export const UninstallMode = {
  NONE: 0,
  NORMAL: 1,
  UNINSTALL_AND_REMOVE_SENSOR: 2,
  UNINSTALL_AND_REINSTALL_ON_NEW_LOCATION: 3,
  JUST_REMOVE_SENSOR: 4,
};

export const DRAW_TYPES = {
  POINT: 'Point',
  POLYGON: 'Polygon',
  LINE: 'LineString',
  CIRCLE: 'Circle'
};

export const PRESSURE_DISPLAY_MODE = {
  DAY: 0,
  WEEK: 1
};

export const graphsBackgroundColor = '#fff';
export const graphsIncludedBackgroundColor = '#fffcce';

export const COUPLE_SAMPLES_COUNT_FOR_PAGE = 45;
export const SENSOR_LOG_COUNT_FOR_PAGE = 30;
export const NOISE_ALERTS_COUNT = 15;
export const TENSOR_DAYS_BACK = 15;
export const DEVICE_DIGITS = 15;
export const GOOGLE_API_KEY = 'AIzaSyDLVG10lCnha8qbk-V3R5lwdFzSA1JeWb4';
export const BING_MAPS_KEY = 'AiKdmAK4mNhqD4Z7undCZ4GU4cdpxKoHhz4TQMYsvIKCT1IOcGciIH9iHDRVnn1S';

